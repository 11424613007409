import React from "react"

// import Layout from "../components/layout"
// import SEO from "../components/seo"

const NotFoundPage = () => (
  <p>Oops No Thing here !</p>
)

export default NotFoundPage
